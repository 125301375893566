html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield !important;
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: #e5e5e5;
  margin: 8px auto;
}
.separator:last-child {
  display: none;
}
.abandoned_items_flex {
  display: flex;
  justify-items: space-between;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.abandoned_summary_grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.abandoned_summary_grid > div {
  margin-bottom: 0.5rem;
}

.abandoned_flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.abandoned-customer {
  max-width: 300px;
}
.hr-break {
  height: 1px;
  background-color: #a6a6a6;
  max-width: 100%;
  margin: 40px 0 15px 0;
  opacity: 0.5;
}
.justifyContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.justifyTop {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.justifyCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.justifyRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.flex {
  display: flex;
  align-items: center;
}
.exceed {
  color: red;
  font-size: 12px;
  display: inline-block;
  width: 240px;
  margin: 5px 10px;
  height: 29px;
}
.customize-search {
  display: flex;
  position: relative;
  width: 100%;
}
.customize-search form {
  width: 100%;
}
.search-icon {
  position: absolute;
  left: 1%;
  top: 25%;
  z-index: 99;
  filter: invert(40%);
}
.cancel-button {
  position: absolute;
  right: 1%;
  top: 25%;
  z-index: 99;
  filter: invert(40%);
  cursor: pointer;
}
.cursorPointer {
  cursor: pointer;
}
.holdFulfillMenu {
  position: relative;
}
.threeDots {
  position: absolute;
  right: 2%;
  top: -20px;
}
.customize-search .Polaris-TextField__Input {
  padding: 0 40px;
}
#myFieldIDError {
  margin: 10px 0;
}
.propertiesHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  margin-bottom: 8px;
}
.ordersItems p {
  font-size: 14px;
  margin-bottom: 8px;
}
.ordersItems p:first-of-type {
  width: 70%;
}

.propertytems p:first-of-type {
  word-wrap: break-word;
}

.ordersItemsW25 p {
  font-size: 14px;
  margin-bottom: 8px;
  width: 30%;
}

.ordersItems p:last-of-type {
  width: 30%;
  text-align: right;
}

.propertyItems p:last-of-type {
  word-wrap: break-word;
  overflow-y: auto;
  max-height: 100px;
}

.pendingInfo p:first-of-type {
  width: 25%;
}
.pendingInfo p:nth-of-type(2) {
  width: 45%;
}
.pendingInfo p:last-of-type {
  width: 30%;
  text-align: right;
}
.refundInfo p {
  font-size: 14px;
  margin-bottom: 8px;
}
.refundInfo p:first-of-type {
  width: 24%;
}
.refundInfo p:nth-of-type(2) {
  width: 26%;
}
.refundInfo p:nth-of-type(3) {
  width: 24%;
}
/* .refundInfo p:nth-of-type(4) {
  width: 25%;
} */
.refundInfo p:last-of-type {
  width: 26%;
  text-align: right;
}
.paymentDetails p:first-of-type {
  width: 24%;
}
.paymentDetails p:last-of-type {
  width: 64%;
  text-align: right;
}
.stats {
  margin-left: -10px;
}
.link {
  color: #2c6ecb;
}

.labelsAlignment {
  display: flex;
  flex-wrap: wrap;
}
.primaryPart {
  width: 30%;
}

.secondaryPart {
  width: 70%;
}

.datePopover {
  margin: 10px;
  width: 500px;
  /* min-height: 400px; */
}
.filterDropdown {
  margin: 10px;
  width: 180px;
  height: auto;
}
.justifyContent .Polaris-TextField__Input {
  color: #000 !important;
  -webkit-text-fill-color: #000 !important;
}
.Polaris-Modal-Dialog .Polaris-Modal-CloseButton {
  margin-right: 5px;
}
.refund-search {
  width: 200px;
}
.refund-search.customize-search .Polaris-TextField__Input {
  padding: 0 25px 0 10px;
}
.filterDropdown .Polaris-Select {
  width: 120px;
}
.filterTag {
  display: inline-block;
  padding: 4px 10px;
  border-radius: 3px;
  background-color: #e1e1e1;
  color: rgb(49, 49, 49);
  font-size: 14px;
}
.utm-analytics-table
  .Polaris-IndexTable__Table--unselectable
  .Polaris-IndexTable__TableCell:first-child {
  max-width: 400px;
}
.strike {
  text-decoration: line-through;
}
.strike .Polaris-Link {
  color: #6d7175;
}
.user-summary .Polaris-ResourceItem__Container {
  padding: 0;
  cursor: default;
}
.create-password .Polaris-Link:focus:not(:active) {
  outline: none;
}
.role-button .Polaris-Button.Polaris-Button--disabled {
  width: 200px;
}
.role-button
  .Polaris-Button:not(.Polaris-Button--plain)
  .Polaris-Button__Content {
  width: 100%;
  text-align: left;
  justify-content: flex-start;
}
.user-summary ul {
  list-style-type: none;
  padding: 5px 10px;
}
.Polaris-Navigation__Section
  .Polaris-Navigation__Badge
  .Polaris-Badge--statusNew {
  color: #fff;
  font-weight: var(--p-font-weight-medium);
  border: none;
  background-color: #4c0a84;
}

.new-template:not(:last-of-type) {
  display: none;
}
.params-row .Polaris-Icon {
  margin: 5px 0;
}

.templateContent .Polaris-Label {
  width: 100%;
}

.abc-quantity .Polaris-TextField__Prefix {
  margin: 0 3px;
}

@media screen and (max-width: 768px) {
  .abandoned_items_flex {
    flex-direction: column;
    row-gap: 0.5rem;
  }
  .abandoned-customer {
    width: 100%;
    max-width: 100%;
  }
}

.indexCell {
  max-width: 500px;
  overflow: scroll;
}

/* .indexCell::-webkit-scrollbar {
  width: 0;
  height: 0;
} */

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b5b4b3;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

.utmFilterTags {
  overflow: hidden;
  max-width: 200px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
  margin: 5px 5px;
}

.last-row-table {
  background: #008060 !important;
  color: white !important;
}

@media screen and (max-width: 480px) {
  .subtitle {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.divider {
  width: 95%;
  height: 2px;
  border-radius: 4px;
  margin: 2px auto;
  background: #dcdcdc;
}

.single-line .Polaris-Listbox-TextOption{
  height:50px;
}
.double-line .Polaris-Listbox-TextOption{
  height: 65px;
}
.custom-modal-title{
  text-align: center;
  margin-left: 44px;
}
.modal-note-style{
  font-size: 11px;
  font-style: italic;
}
.modal-body-style{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}